import { downloadPdfFile, downloadExcelFile, downloadPdfServerFilename } from "@/libs/helpers";
import { httpClient } from "@/libs/http";
import { BARCODE_FINISH_PACKING_1SKU, BARCODE_MULTIPACK } from "@/libs/const";

export default {
    props: {
        orders: {
            type: Array,
            default: () => []
        },
        presentItems: {
            type: Array,
            default: () => []
        },
        basket: {
            type: Object,
            default: () => { }
        },
        command: {
            type: Object,
            default: () => { }
        },
        isMissing: {
            type: Boolean,
            default: () => false
        },
        isEnableLastOrder: {
            type: Boolean,
            default: () => false
        },
    },
    data: () => ({
        order: {},
        code: null,
        scanned: [],
        packaging: [],
        specialIds: [],
        isPrinted: false,
        waitingSpecialGoods: null,
        lastIdGoods: null,
        dialogConfirm: false,
        dialogSelectSKu: false,
        dialogAddItemToPack: false,
        groupItems: [],
        groupScanItems: [],
        groupScanning: 0,
        duplicateSkus: []
    }),
    computed: {
        checkingCodes() {
            return ['' + this.basket.code]
        },
        isGoodsScannedAll() {
            let countNotScanned = 0
            if (!this.order || !this.order.items || this.order.items.length === 0) {
                return false
            }
            this.order.items.forEach(i => {
                const sItem = [...this.scanned].find(s => i.id_goods == s.id_goods)
                if (!sItem || i.quantity != sItem.quantity) {
                    countNotScanned++
                }
            })
            return countNotScanned === 0
        },
        step() {
            let step = 1
            if (!this.isGoodsScannedAll) {
                step = 1
            } else {
                if (!this.isPrinted) {
                    step = 2
                } else if (this.packaging.length === 0) {
                    step = 3
                } else {
                    step = 4
                }
            }
            return step
        },
        label() {
            let label = null
            switch (this.step) {
                case 1:
                    label = this.$t('labels.qr_sku_sub_barcode')
                    break;
                case 2:
                    if (this.groupItems.length > 0 && this.groupScanning > 0) {
                        label = BARCODE_MULTIPACK
                    } else {
                        label = this.$t('labels.basket_code')
                    }
                    break;
                case 3:
                    label = this.$t('labels.package_code')
                    break;
                case 4:
                    if (this.groupItems.length > 0) {
                        label = this.$t('labels.tracking')
                    } else {
                        label = this.$t('labels.package_code_tracking')
                    }
                    break;
            }
            return label
        },
        orderGoodsIds() {
            if (!this.command || !this.command.id) {
                return []
            }
            if (this.command.process_type === 1) {
                const ids = []
                for (let i = 0; i < this.orders.length; i++) {
                    const items = this.orders[i].items
                    if (!ids.includes(items[0].id_goods)) {
                        ids.push(items[0].id_goods)
                    }
                }
                return ids
            } else if (this.command.process_type === 4) {
                const ids = []
                for (let i = 0; i < this.orders.length; i++) {
                    const items = this.orders[i].items
                    for (let j = 0; j < items.length; j++) {
                        const item = items[j];
                        if (!ids.includes(item.id_goods)) {
                            ids.push(item.id_goods)
                        }
                    }
                }
                return ids
            } else {
                if (!this.order || !this.order.id) {
                    return []
                }
                return [...this.order.items].map(i => (i.id_goods))
            }
        },
    },
    mounted() {
        this.getOrderDefault()
    },
    methods: {
        downloadPdfFile,
        downloadExcelFile,
        downloadPdfServerFilename,
        setQuantityItemNoPack() {
            let scanRequired = 0
            for (let i = 0; i < this.order.items.length; i++) {
                const item = this.order.items[i];
                if (item.pack_scan_required == '0') {
                    this.scanned.push({
                        id_goods: item.id_goods,
                        quantity: item.quantity
                    })
                } else {
                    scanRequired++
                }
            }

            if (!scanRequired) {
                setTimeout(() => {
                    this.code = this.basket.code
                    this.scanBasketAndPrintStamp()
                }, 100)
            }
        },
        getOrderDefault() {
            /* if (window.me && window.me.working && window.me.working.packing && window.me.working.packing_item) {
                const working = JSON.parse(window.me.working.packing_item)
                this.order = { ...working.order }
            } else */
            if (this.orders && this.orders.length === 1 && this.orders[0]) {
                this.order = { ...this.orders[0] }
                this.setQuantityItemNoPack()
                this.startPacking()
                this.$vToastify.warning(this.$t("labels.last_order_in_basket"));
                if (this.isEnableLastOrder) {
                    setTimeout(() => {
                        document.getElementById("DonCuoiCuaSot_player").play();
                    }, 900)
                }
            } else if ([2, 3].includes(this.command.process_type) && this.orders[0]) {
                this.order = { ...this.orders[0] }
                this.setQuantityItemNoPack()
                this.startPacking()
            } else if (this.command.process_type === 1) {
                let isAllSame = true
                let idGoods = 0
                let isNoLabel = true
                for (let o = 0; o < this.orders.length; o++) {
                    const order = this.orders[o];
                    for (let i = 0; i < order.items.length; i++) {
                        const item = order.items[i];
                        if (idGoods == 0) {
                            idGoods = item.id_goods
                        }
                        if (idGoods != item.id_goods) {
                            isAllSame = false
                        }
                        if (item.no_label == '0') {
                            isNoLabel = false
                        }
                    }
                }
                if (isAllSame || isNoLabel) {
                    this.order = { ...this.orders[0] }
                    this.setQuantityItemNoPack()
                    this.startPacking()
                }
            }
        },

        inputScan() {
            if (!this.code) {
                return false
            }

            switch (this.step) {
                case 1:
                    this.scanSku()
                    break
                case 2:
                    this.scanBasketAndPrintStamp()
                    break
                case 3:
                    this.scanPackaging()
                    break
                case 4:
                    this.packingConfirm()
                    break
            }
        },

        getGoodsCodes(item) {
            const goodsCodes = [`${item.sku}`]
            /*if (item.customer_goods_barcode) {
                goodsCodes.push(item.customer_goods_barcode.toUpperCase())
            }*/
            if (item.sub_barcode) {
                goodsCodes.push(item.sub_barcode.toUpperCase())
            }
            return goodsCodes
        },

        showAddItemToPackDialog() {
            this.code = null
            if (this.groupScanning === 0) {
                this.$vToastify.error(this.$t('messages.un_scanned_goods'))
                return false
            }
            this.dialogAddItemToPack = true
        },

        hideAddItemToPackDialog() {
            this.dialogAddItemToPack = false
        },

        addItemsToPack(data) {
            const checkPackaging = this.packaging.find(p => p.barcode.toUpperCase() === data.barcode.toUpperCase())
            if (checkPackaging) {
                const packaging = [...this.packaging].map(p => {
                    if (p.id == checkPackaging.id) {
                        p.quantity++
                    }
                    return p
                })
                this.packaging = [...packaging]
            } else {
                this.packaging.push({
                    ...data,
                    quantity: 1
                })
            }

            const quantity = this.groupScanning
            const items = [...this.groupScanItems]
            this.groupItems.push({
                items,
                quantity
            })

            this.hideAddItemToPackDialog()
            document.getElementById('success_sound_player').play()

            this.groupScanning = 0
            this.groupScanItems = []
        },

        async scanSku() {
            if (this.code === BARCODE_FINISH_PACKING_1SKU) {
                this.showConfirmDialog()
                return false
            }

            if (this.code === BARCODE_MULTIPACK) {
                this.showAddItemToPackDialog()
                return false
            }

            if (this.isLoading) {
                this.code = null
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            let code = this.code
            if (code.includes("/")) {
                const codeSpl = code.split("/");
                code = codeSpl[codeSpl.length - 1];
                code = code.toLowerCase();
            }

            try {
                const { data } = await httpClient.post('/goods-issue-detail/v1/packing-scan-uid', {
                    code,
                    goods_ids: this.orderGoodsIds
                })

                if (!data || data.length === 0) {
                    if (!this.order || !this.order.id) {
                        let order = {}
                        let isPresentError = false
                        for (let o = 0; o < this.orders.length; o++) {
                            const ord = { ...this.orders[o] }
                            const otem = ord.items.find(i => this.getGoodsCodes(i).includes(this.code.toUpperCase()))
                            if (otem) {
                                if (this.presentItems && this.presentItems.length > 0 && !this.presentItems.includes(otem.id_goods)) {
                                    isPresentError = true
                                    break
                                }
                                order = { ...ord }
                                break
                            }
                        }

                        if (isPresentError) {
                            this.$vToastify.error(this.$t('messages.scan_present_item_first'));
                            document.getElementById('DonCoQuaTang_player').play()

                            this.code = null
                            this.isLoading = false
                            return false
                        }

                        if (!order || !order.id) {
                            this.$vToastify.error(this.$t('labels.order_not_found'));
                            document.getElementById('error_sound_player').play()

                            this.code = null
                            this.isLoading = false
                            return false
                        }

                        this.order = { ...order }
                        this.setQuantityItemNoPack()
                        this.startPacking()
                    }

                    const item = [...this.order.items].find(i => this.getGoodsCodes(i).includes(this.code.toUpperCase()))
                    if (item) {
                        if (item.uid_printed_on_item == '1') {
                            this.$vToastify.error(this.$t('messages.scan_customer_uid_only'))
                            document.getElementById('error_sound_player').play()

                            this.code = null
                            this.isLoading = false
                            return false
                        }
                        if (item.no_label == '0' && item.uid_scan_require == '1') {
                            this.$vToastify.error(this.$t('messages.scan_uid_only'))
                            document.getElementById('error_sound_player').play()

                            this.code = null
                            this.isLoading = false
                            return false
                        }
                    }
                    this.updateItemScanned(item)
                } else {
                    if (data.length > 1) {
                        this.duplicateSkus = [...data]
                        this.dialogSelectSKu = true
                    } else {
                        const uidItem = data[0]
                        const checkSpecial = this.specialIds.includes(uidItem.id)
                        if (checkSpecial) {

                            this.code = null
                            this.isLoading = false
                            this.$vToastify.error(this.$t('messages.uid_scanned'))
                            document.getElementById('error_sound_player').play()
                            return false
                        }

                        if (!this.order || !this.order.id) {
                            let order = {}
                            let isPresentError = false
                            for (let o = 0; o < this.orders.length; o++) {
                                const ord = { ...this.orders[o] }
                                const otem = ord.items.find(i => i.id_goods == uidItem.id_goods)
                                if (otem) {
                                    if (this.presentItems && this.presentItems.length > 0 && !this.presentItems.includes(otem.id_goods)) {
                                        isPresentError = true
                                        break
                                    }
                                    order = { ...ord }
                                    break
                                }
                            }

                            if (isPresentError) {
                                this.$vToastify.error(this.$t('messages.scan_present_item_first'));
                                document.getElementById('DonCoQuaTang_player').play()

                                this.code = null
                                this.isLoading = false
                                return false
                            }

                            this.order = { ...order }
                            this.setQuantityItemNoPack()
                            this.startPacking()
                        }

                        if (this.order.id_warehouse != uidItem.id_warehouse) {
                            this.code = null
                            this.isLoading = false
                            this.$vToastify.error(this.$t('messages.id_other_wh'))
                            document.getElementById('error_sound_player').play()
                            return false
                        }

                        const item = [...this.order.items].find(i => i.id_goods == uidItem.id_goods)
                        this.updateItemScanned(item, uidItem.id)
                    }
                }

                this.code = null
                this.isLoading = false
            } catch (e) {
                this.isLoading = false
                this.code = null
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
                document.getElementById('error_sound_player').play()
            }
        },

        updateItemScanned(item, specialId = null) {
            if (!item || !item.id_goods) {
                this.$vToastify.error(this.$t('messages.goods_not_found'))
                document.getElementById('error_sound_player').play()
                return false
            }
            const checkScanned = [...this.scanned].find(s => s.id_goods == item.id_goods)
            if (!checkScanned) {
                if (specialId) {
                    this.specialIds.push(specialId)
                }
                document.getElementById('success_sound_player').play()
                this.scanned.push({
                    id_goods: item.id_goods,
                    quantity: 1
                })
            } else {
                if (checkScanned.quantity >= item.quantity) {
                    this.$vToastify.error(this.$t('messages.wrong_quantity'));
                    document.getElementById('error_sound_player').play()
                    return false
                }
                this.scanned = [...this.scanned].map(s => {
                    if (s.id_goods == item.id_goods) {
                        s.quantity += 1
                    }
                    return s
                })
                if (specialId) {
                    this.specialIds.push(specialId)
                }
                document.getElementById('success_sound_player').play()
            }
            this.lastIdGoods = item.id_goods

            this.groupScanning++
            if (specialId) {
                this.groupScanItems.push(specialId)
            }

            if (this.command && this.command.process_type === 1) {
                setTimeout(() => {
                    this.code = this.basket.code
                    this.scanBasketAndPrintStamp()
                }, 100)
            }
        },

        async createPackGroupAndPrintStamp() {
            if (this.isLoading) {
                this.code = null
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                const { data } = await httpClient.post('/goods-issue-detail/v1/packing-create-group-stamps', {
                    id: this.order.id,
                    tracking_id: this.order.tracking_id,
                    groups: this.groupItems,
                })

                this.isLoading = false
                this.groupItems = [...data]

                this.downloadExcelFile(`${process.env.VUE_APP_API_V2_URL}/common/v1/generate-order-group-stamps`, {
                    id: this.order.id,
                    tracking_id: this.order.tracking_id,
                    groups: this.groupItems,
                }, `100x80_${this.order.tracking_id}.pdf`)
                this.isPrinted = true

                document.getElementById('success_sound_player').play()
            } catch (e) {
                this.isLoading = false
                this.code = null
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
                document.getElementById('error_sound_player').play()
            }

        },

        async scanBasketAndPrintStamp() {
            if (this.code === BARCODE_MULTIPACK) {
                this.showAddItemToPackDialog()
                return false
            }

            if (this.code !== BARCODE_MULTIPACK && this.groupScanning > 0 && this.groupItems.length > 0) {
                this.$vToastify.error(this.$t('messages.wrong_code', { code: this.code }));
                document.getElementById('error_sound_player').play()
                this.code = null
                return false
            }

            if (this.code != this.basket.code) {
                this.$vToastify.error(this.$t('messages.wrong_basket_code'));
                document.getElementById('error_sound_player').play()
                this.code = null
                return false
            }

            document.getElementById('success_sound_player').play()
            this.code = null

            if (this.groupItems.length > 0) {
                console.log(this.groupItems)
                this.createPackGroupAndPrintStamp()
            } else {
                // await this.downloadPdfFile(`${process.env.VUE_APP_WEB_V1_URL}/print/goods-issue-pdf-stamp-by-goods-issue-detail-tracking-v3?tracking_id=${this.order.tracking_id}`)
                this.downloadPdfServerFilename(
                    "/download/v1/order-stamp",
                    { tracking_id: this.order.tracking_id },
                );
                this.isPrinted = true

                if (this.order.id_customer_license && this.order.count_stamp_item > 0) {
                    const workConfigs = this.command.work_configs ? JSON.parse(this.command.work_configs) : []
                    const workConfig = [...workConfigs].find(wcf => wcf.key == 'stamp_other' && wcf.type == 'package')
                    let stamp_prefix = '100x80'
                    if (workConfig) {
                        stamp_prefix = workConfig.size
                    }
                    await this.downloadExcelFile(
                        "/common/v1/generate-order-item-stamp",
                        {
                            id: this.order.id,
                        },
                        `${stamp_prefix}_sp_${this.order.tracking_id}.pdf`
                    );
                }
            }
            this.startPrintStamp()
        },
        async scanPackaging() {
            const checkPackaging = this.packaging.find(p => p.barcode.toUpperCase() === this.code.toUpperCase())
            if (checkPackaging) {
                const packaging = [...this.packaging].map(p => {
                    if (p.id == checkPackaging.id) {
                        p.quantity++
                    }
                    return p
                })
                this.packaging = [...packaging]
                this.code = null
                document.getElementById('success_sound_player').play()
                return false
            }

            if (this.isLoading) {
                this.code = null
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                const { data } = await httpClient.post('/goods-issue-detail/v1/packing-scan-packaging', {
                    code: this.code,
                    id_customer: this.order.id_customer,
                    id_shop: this.order.id_customer_e_marketplace_shop
                })

                this.isLoading = false
                this.code = null
                document.getElementById('success_sound_player').play()
                this.packaging.push({
                    ...data,
                    quantity: 1
                })
            } catch (e) {
                this.isLoading = false
                this.code = null
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
                document.getElementById('QuetMaBaoBiHoacMaDonHang_player').play()
            }
        },
        removePackaging(id) {
            this.packaging = [...this.packaging.filter(p => p.id !== id)]
            this.$refs.inputScanRef.focus()
        },
        async packingConfirm() {
            if (this.groupItems.length > 0) {
                const unCheckedItems = [...this.groupItems].filter(item => item.status === 0)
                if (unCheckedItems.length > 0) {
                    const unCheckedCodes = [...unCheckedItems].map(item => (item.code))
                    if (!unCheckedCodes.includes(this.code)) {
                        this.$vToastify.error(this.$t('messages.wrong_code', { code: this.code }));
                        document.getElementById('error_sound_player').play()
                        this.code = null
                        return false
                    } else {
                        const groupItems = [...this.groupItems].map(item => {
                            if (item.code === this.code) {
                                item.status = 1
                            }
                            return item
                        })
                        this.groupItems = [...groupItems]
                        document.getElementById('success_sound_player').play()
                        this.code = null

                        const reCheckedItems = [...this.groupItems].filter(item => item.status === 0)
                        if (reCheckedItems.length > 0) {
                            return false
                        }
                    }
                }
            } else if (![this.order.tracking_id, this.order.customer_order_id, this.order.delivery_order_id].includes(this.code)) {
                await this.scanPackaging()
                return false
            }

            if (this.isLoading) {
                this.code = null
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true


            const table = JSON.parse(localStorage.getItem('_pktb') || '{id: null}')
            const idTable = table.id || null
            try {
                await httpClient.post('/goods-issue-detail/v1/packing-confirm', {
                    id: this.order.id,
                    id_basket: this.basket.id,
                    id_pickup_command: this.command.id,
                    packaging: this.packaging,
                    special_ids: this.specialIds,
                    id_table: idTable,
                    groups: this.groupItems
                })

                this.isLoading = false
                this.code = null
                document.getElementById('success_sound_player').play()
                this.updateWorking({ type: 'packing', item: null })

                // reset
                this.order = {}
                this.scanned = []
                this.packaging = []
                this.isPrinted = false
                this.waitingSpecialGoods = null
                this.lastIdGoods = null
                this.specialIds = []
                this.groupItems = []

                this.$emit('success')
            } catch (e) {
                this.isLoading = false
                this.code = null
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
                document.getElementById('error_sound_player').play()
            }
        },

        showConfirmDialog() {
            this.code = null
            if (this.command.process_type !== 1) {
                this.$vToastify.error(this.$t('messages.finish_basket_one_only'));
                document.getElementById('error_sound_player').play()
                return false
            }
            this.dialogConfirm = true
        },

        hideConfirmDialog() {
            this.dialogConfirm = false
        },

        async printStampMissingQuantity({ tracking_ids }) {
            let stampSize = '100x80'
            const { warehouses } = window.me
            const warehouse = [...warehouses].find(w => w.id == this.command.id_warehouse)
            if (warehouse && warehouse.work_configs) {
                const workConfigs = JSON.parse(warehouse.work_configs)
                const warehouseConfig = [...workConfigs].find(cf => cf.key == 'stamp_missing')
                stampSize = warehouseConfig && warehouseConfig.size || '100x80'
            }

            this.downloadExcelFile(`${process.env.VUE_APP_API_V2_URL}/common/v1/generate-pickup-missing-quantity`, {
                id_basket: this.basket.id,
                tracking_ids,
                id_warehouse: this.command.id_warehouse
            }, `${stampSize}_${this.basket.code}.pdf`)
        },

        async finishBasket1Sku() {
            const basket_code = localStorage.getItem('_pbct1')
            if (basket_code && basket_code != this.basket.code) {
                this.$vToastify.error(this.$t('messages.not_finish_basket_one', { basket_code: basket_code }))
                document.getElementById('error_sound_player').play()
                return false
            }

            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                const { data } = await httpClient.post('/goods-issue-detail/v1/packing-finish-basket-1sku', {
                    id_basket: this.basket.id,
                    id_command: this.command.id
                })
                this.isLoading = false
                if (data.tracking_ids && data.tracking_ids.length > 0 && data.employee_name) {
                    this.printStampMissingQuantity({ tracking_ids: data.tracking_ids })
                    alert(this.$t('messages.finish_basket_one_warning', { tracking_ids: data.tracking_ids.join('; '), employee_name: data.employee_name }))
                }
                this.$refs.inputScanRef.focus()
                document.getElementById('success_sound_player').play()
                this.$emit('finishBasket')
            } catch (e) {
                this.isLoading = false
                this.$refs.inputScanRef.focus()
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
                document.getElementById('error_sound_player').play()
            }
        },

        hideSelectSkuDialog() {
            this.dialogSelectSKu = false
            this.duplicateSkus = []
        },

        confirmSelectSku({ sku, id }) {
            this.hideSelectSkuDialog()
            const checkSpecial = this.specialIds.includes(id)
            if (checkSpecial) {
                this.code = null
                this.$vToastify.error(this.$t('messages.uid_scanned'));
                document.getElementById('error_sound_player').play()
                return false
            }
            //this.specialIds.push(id)

            if (!this.order || !this.order.id) {
                let order = {}
                for (let o = 0; o < this.orders.length; o++) {
                    const ord = { ...this.orders[o] }
                    const otem = ord.items.find(i => this.getGoodsCodes(i).includes(sku))
                    if (otem) {
                        order = { ...ord }
                        break
                    }
                }
                if (!order || !order.id) {
                    this.$vToastify.error(this.$t('labels.order_not_found'));
                    document.getElementById('error_sound_player').play()
                    return false
                }
                this.order = { ...order }
                this.setQuantityItemNoPack()
                this.startPacking()
            }

            const item = [...this.order.items].find(i => this.getGoodsCodes(i).includes(sku))
            this.updateItemScanned(item, id)
        },

        startPacking() {
            if (this.order && this.order.id) {
                const table = JSON.parse(localStorage.getItem('_pktb') || '{id: null}')
                const idTable = table.id || null
                httpClient.post('/goods-issue-detail/v1/packing-start-order', {
                    id: this.order.id,
                    id_basket: this.basket.id,
                    id_table: idTable,
                })
            }
        },

        startPrintStamp() {
            if (this.order && this.order.id) {
                httpClient.post('/goods-issue-detail/v1/packing-start-print', {
                    id: this.order.id,
                })

                this.updateWorking({
                    type: 'packing',
                    item: JSON.stringify({
                        orders: this.orders,
                        basket: this.basket,
                        command: this.command,
                        order: this.order,
                    })
                })

            }
        }
    },
}
